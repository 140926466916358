form {
  display: flex;
  flex-direction: row;
  max-width: 500px;
  // put two items in each row
  flex: 1 1 50%;
  // flex: 2 1 auto;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: $bp-tablet) {
    flex-direction: row;
    max-width: 90%;
    margin: 0 auto 0 auto;
  }

  @media (min-width: $bp-desktop) {
    flex-direction: row;
    max-width: 90%;
    margin: 0;
  }

  button,
  input {
    border-radius: 0.3em;
    padding: 20px 25px;
  }

  input {
    border: 2px solid $border-color;
    color: $font-color;
    margin-bottom: 15px;
    width: 100%;
    min-width: 300px;

    @media (min-width: $bp-tablet) {
      flex-grow: 1;
      margin-bottom: 0;
      margin-right: 15px;
    }

    @media (min-width: $bp-desktop) {
      flex-grow: 1;
      margin-right: 15px;
      max-width: 300px;

    }

    &::placeholder {
      color: $font-color-light;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 2px solid $accent-color;
      outline: none;
    }
  }

  button {
    background-color: $accent-color;

    // flex-grow: 1;
    border: none;
    color: $white;
    cursor: pointer;
    font-family: $font-bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $secondary-accent-color;
    }

    @media (min-width: $bp-tablet) {
      width: auto;
    }

  }
}

#afterSubmit {
  display: none;
}