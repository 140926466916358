.hero-image-wrapper {
    display: none;
    margin-left: 75px;
    border-radius: 50px;

    @media (min-width: $bp-desktop) {
        display: block;
    }

    img {
        display: block;
        width: 100%;
    }
}
.imgst {
    border-radius: 15px;
}