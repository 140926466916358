$accent-color: #3B88D4;
$background-color: #000000; //#F6F9FE;
$border-color: #E3E3E3;
$font-color: #545454;
$font-color-accent: #CCC1E6;
$font-color-light: #B6B6B6;
$heading-color: linear-gradient("45deg", "#3b88d4", "#f587ac");

// #353252;
$white: #ffffff;
$secondary-accent-color: #f587ac;

$bp-tablet: 760px;
$bp-desktop: 1024px;