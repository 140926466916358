.color-bg {
  background-color: $background-color;
  // border-radius: 10px;
  // margin: 20px;
  // min-height: calc(100vh - 40px);
  min-height: 100vh;
  // padding: 50px 20px 20px 20px;

  @media (min-width: $bp-tablet) {
    padding: 50px 70px;
  }

  @media (min-width: $bp-desktop) {
    padding: 40px 130px;
  }

  .wrapper {
    // margin: auto;
    max-width: 1140px;
  }
}
