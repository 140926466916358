*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: $white;
  color: $font-color;
  font-family: $font-regular;
  font-size: 17px;
  line-height: 1.5em;

  &.disable-scroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  background: -webkit-linear-gradient(top right, #3b88d4, #f587ac);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // color: $heading-color;
  font-family: $font-bold;
  margin: 0;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
